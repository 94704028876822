<!-- 夜审设置 -->
<template>
  <section class="cont nightAuditSettings">
    <Breadcrumb :crumbs="crumbs" />
    <el-row class="content-box">
      <el-row class="table-box">
        <!-- 表格按钮 -->
        <div class="table-head-btn">
          <h6>夜审开关</h6>
          <label class="m-right-15">夜审时间</label>
          <el-time-picker v-model="time" placeholder="夜审时间" value-format="HH:mm:ss" :clearable="false"></el-time-picker>
          <el-row class="right-btn">
            <el-switch v-model="active" active-text="开启" inactive-text="关闭" @change="options"></el-switch>
          </el-row>
        </div>
      </el-row>
    </el-row>
  </section>
</template>

<script>
  import { setNightTrial, getNightTrial } from '@/api/pms/nightAudit/nightAudit'
  import { mapState } from 'vuex'
  export default {
    name: 'nightAuditSettings',
    data() {
      return {
        crumbs: new Map([['PMS'], ['夜审中心'], ['夜审设置']]),
        time: '',
        active: false,
      }
    },
    computed: {
      ...mapState(['hotelInfo']),
    },
    mounted() {
      this.getData()
    },
    methods: {
      getData() {
        getNightTrial(this.hotelInfo.id).then(({ success, records }) => {
          if (success) {
            this.active = records.time ? true : false
            this.time = records.time ? records.time + ':00' : ''
          }
        })
      },
      options(bool) {
        if (this.time) {
          let arr = this.time.split(':')
          let str = `${arr[2] * 1} ${arr[1] * 1} ${arr[0] * 1} * * ?`
          let time = `${arr[0]}:${arr[1]}`
          let data = {
            hotelId: this.hotelInfo.id,
            cronTime: str,
            time: time,
            state: bool ? 1 : 0,
          }
          const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)',
          })
          setNightTrial(data).then(({ success }) => {
            if (success) {
              loading.close()
              this.$message({ showClose: true, message: '配置成功！', type: 'success' })
              this.getData()
            }
          })
        } else {
          this.$message({ showClose: true, message: '请选择夜审时间！', type: 'error' })
          this.active = !this.active
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .nightAuditSettings {
    h6 {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }
</style>
